import React from 'react'
import TopStories from '../component/TopStories'
import Banner from './Banner'
import LatestNews from '../component/LatestNews'




export default function Home() {
  return (
  <>
      <Banner />
      <TopStories />
      <LatestNews />
   </>
  )
}
